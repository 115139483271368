.splide__container {
  box-sizing: border-box;
  position: relative;
}

.splide__list {
  backface-visibility: hidden;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  margin: 0 !important;
  padding: 0 !important;
}

.splide.is-initialized:not(.is-active) .splide__list {
  display: block;
}

.splide__pagination {
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0;
  pointer-events: none;
}

.splide__pagination li {
  display: inline-block;
  line-height: 1;
  list-style-type: none;
  margin: 0;
  pointer-events: auto;
}

.splide:not(.is-overflow) .splide__pagination {
  display: none;
}

.splide__progress__bar {
  width: 0;
}

.splide {
  position: relative;
  visibility: hidden;
}

.splide.is-initialized, .splide.is-rendered {
  visibility: visible;
}

.splide__slide {
  backface-visibility: hidden;
  box-sizing: border-box;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  list-style-type: none !important;
  margin: 0;
  position: relative;
}

.splide__slide img {
  vertical-align: bottom;
}

.splide__spinner {
  animation: splide-loading 1s linear infinite;
  border: 2px solid #999;
  border-left-color: transparent;
  border-radius: 50%;
  bottom: 0;
  contain: strict;
  display: inline-block;
  height: 20px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
}

.splide__sr {
  clip: rect(0 0 0 0);
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.splide__toggle.is-active .splide__toggle__play, .splide__toggle__pause {
  display: none;
}

.splide__toggle.is-active .splide__toggle__pause {
  display: inline;
}

.splide__track {
  overflow: hidden;
  position: relative;
  z-index: 0;
}

@keyframes splide-loading {
  0% {
    transform: rotate(0);
  }
  to {
    transform: rotate(1turn);
  }
}
.splide__track--draggable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.splide__track--fade > .splide__list > .splide__slide {
  margin: 0 !important;
  opacity: 0;
  z-index: 0;
}

.splide__track--fade > .splide__list > .splide__slide.is-active {
  opacity: 1;
  z-index: 1;
}

.splide--rtl {
  direction: rtl;
}

.splide__track--ttb > .splide__list {
  display: block;
}

.splide__arrow {
  -ms-flex-align: center;
  align-items: center;
  background: #ccc;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  height: 2em;
  -ms-flex-pack: center;
  justify-content: center;
  opacity: 0.7;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 2em;
  z-index: 1;
}

.splide__arrow svg {
  fill: #000;
  height: 1.2em;
  width: 1.2em;
}

.splide__arrow:hover:not(:disabled) {
  opacity: 0.9;
}

.splide__arrow:disabled {
  opacity: 0.3;
}

.splide__arrow:focus-visible {
  outline: 3px solid #0bf;
  outline-offset: 3px;
}

.splide__arrow--prev {
  left: 1em;
}

.splide__arrow--prev svg {
  transform: scaleX(-1);
}

.splide__arrow--next {
  right: 1em;
}

.splide.is-focus-in .splide__arrow:focus {
  outline: 3px solid #0bf;
  outline-offset: 3px;
}

.splide__pagination {
  bottom: 0.5em;
  left: 0;
  padding: 0 1em;
  position: absolute;
  right: 0;
  z-index: 1;
}

.splide__pagination__page {
  background: #ccc;
  border: 0;
  border-radius: 50%;
  display: inline-block;
  height: 8px;
  margin: 3px;
  opacity: 0.7;
  padding: 0;
  position: relative;
  transition: transform 0.2s linear;
  width: 8px;
}

.splide__pagination__page.is-active {
  background: #fff;
  transform: scale(1.4);
  z-index: 1;
}

.splide__pagination__page:hover {
  cursor: pointer;
  opacity: 0.9;
}

.splide__pagination__page:focus-visible {
  outline: 3px solid #0bf;
  outline-offset: 3px;
}

.splide.is-focus-in .splide__pagination__page:focus {
  outline: 3px solid #0bf;
  outline-offset: 3px;
}

.splide__progress__bar {
  background: #ccc;
  height: 3px;
}

.splide__slide {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.splide__slide:focus {
  outline: 0;
}

@supports (outline-offset: -3px) {
  .splide__slide:focus-visible {
    outline: 3px solid #0bf;
    outline-offset: -3px;
  }
}
@media screen and (-ms-high-contrast: none) {
  .splide__slide:focus-visible {
    border: 3px solid #0bf;
  }
}
@supports (outline-offset: -3px) {
  .splide.is-focus-in .splide__slide:focus {
    outline: 3px solid #0bf;
    outline-offset: -3px;
  }
}
@media screen and (-ms-high-contrast: none) {
  .splide.is-focus-in .splide__slide:focus {
    border: 3px solid #0bf;
  }

  .splide.is-focus-in .splide__track > .splide__list > .splide__slide:focus {
    border-color: #0bf;
  }
}
.splide__toggle {
  cursor: pointer;
}

.splide__toggle:focus-visible {
  outline: 3px solid #0bf;
  outline-offset: 3px;
}

.splide.is-focus-in .splide__toggle:focus {
  outline: 3px solid #0bf;
  outline-offset: 3px;
}

.splide__track--nav > .splide__list > .splide__slide {
  border: 3px solid transparent;
  cursor: pointer;
}

.splide__track--nav > .splide__list > .splide__slide.is-active {
  border: 3px solid #000;
}

.splide__arrows--rtl .splide__arrow--prev {
  left: auto;
  right: 1em;
}

.splide__arrows--rtl .splide__arrow--prev svg {
  transform: scaleX(1);
}

.splide__arrows--rtl .splide__arrow--next {
  left: 1em;
  right: auto;
}

.splide__arrows--rtl .splide__arrow--next svg {
  transform: scaleX(-1);
}

.splide__arrows--ttb .splide__arrow {
  left: 50%;
  transform: translate(-50%);
}

.splide__arrows--ttb .splide__arrow--prev {
  top: 1em;
}

.splide__arrows--ttb .splide__arrow--prev svg {
  transform: rotate(-90deg);
}

.splide__arrows--ttb .splide__arrow--next {
  bottom: 1em;
  top: auto;
}

.splide__arrows--ttb .splide__arrow--next svg {
  transform: rotate(90deg);
}

.splide__pagination--ttb {
  bottom: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  left: auto;
  padding: 1em 0;
  right: 0.5em;
  top: 0;
}

.splide__container {
  box-sizing: border-box;
  position: relative;
}

.splide__list {
  backface-visibility: hidden;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  margin: 0 !important;
  padding: 0 !important;
}

.splide.is-initialized:not(.is-active) .splide__list {
  display: block;
}

.splide__pagination {
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0;
  pointer-events: none;
}

.splide__pagination li {
  display: inline-block;
  line-height: 1;
  list-style-type: none;
  margin: 0;
  pointer-events: auto;
}

.splide:not(.is-overflow) .splide__pagination {
  display: none;
}

.splide__progress__bar {
  width: 0;
}

.splide {
  position: relative;
  visibility: hidden;
}

.splide.is-initialized, .splide.is-rendered {
  visibility: visible;
}

.splide__slide {
  backface-visibility: hidden;
  box-sizing: border-box;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  list-style-type: none !important;
  margin: 0;
  position: relative;
}

.splide__slide img {
  vertical-align: bottom;
}

.splide__spinner {
  animation: splide-loading 1s linear infinite;
  border: 2px solid #999;
  border-left-color: transparent;
  border-radius: 50%;
  bottom: 0;
  contain: strict;
  display: inline-block;
  height: 20px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
}

.splide__sr {
  clip: rect(0 0 0 0);
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.splide__toggle.is-active .splide__toggle__play, .splide__toggle__pause {
  display: none;
}

.splide__toggle.is-active .splide__toggle__pause {
  display: inline;
}

.splide__track {
  overflow: hidden;
  position: relative;
  z-index: 0;
}

@keyframes splide-loading {
  0% {
    transform: rotate(0);
  }
  to {
    transform: rotate(1turn);
  }
}
.splide__track--draggable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.splide__track--fade > .splide__list > .splide__slide {
  margin: 0 !important;
  opacity: 0;
  z-index: 0;
}

.splide__track--fade > .splide__list > .splide__slide.is-active {
  opacity: 1;
  z-index: 1;
}

.splide--rtl {
  direction: rtl;
}

.splide__track--ttb > .splide__list {
  display: block;
}

.splide__arrow {
  -ms-flex-align: center;
  align-items: center;
  background: #ccc;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  height: 2em;
  -ms-flex-pack: center;
  justify-content: center;
  opacity: 0.7;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 2em;
  z-index: 1;
}

.splide__arrow svg {
  fill: #000;
  height: 1.2em;
  width: 1.2em;
}

.splide__arrow:hover:not(:disabled) {
  opacity: 0.9;
}

.splide__arrow:disabled {
  opacity: 0.3;
}

.splide__arrow:focus-visible {
  outline: 3px solid #0bf;
  outline-offset: 3px;
}

.splide__arrow--prev {
  left: 1em;
}

.splide__arrow--prev svg {
  transform: scaleX(-1);
}

.splide__arrow--next {
  right: 1em;
}

.splide.is-focus-in .splide__arrow:focus {
  outline: 3px solid #0bf;
  outline-offset: 3px;
}

.splide__pagination {
  bottom: 0.5em;
  left: 0;
  padding: 0 1em;
  position: absolute;
  right: 0;
  z-index: 1;
}

.splide__pagination__page {
  background: #ccc;
  border: 0;
  border-radius: 50%;
  display: inline-block;
  height: 8px;
  margin: 3px;
  opacity: 0.7;
  padding: 0;
  position: relative;
  transition: transform 0.2s linear;
  width: 8px;
}

.splide__pagination__page.is-active {
  background: #fff;
  transform: scale(1.4);
  z-index: 1;
}

.splide__pagination__page:hover {
  cursor: pointer;
  opacity: 0.9;
}

.splide__pagination__page:focus-visible {
  outline: 3px solid #0bf;
  outline-offset: 3px;
}

.splide.is-focus-in .splide__pagination__page:focus {
  outline: 3px solid #0bf;
  outline-offset: 3px;
}

.splide__progress__bar {
  background: #ccc;
  height: 3px;
}

.splide__slide {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.splide__slide:focus {
  outline: 0;
}

@supports (outline-offset: -3px) {
  .splide__slide:focus-visible {
    outline: 3px solid #0bf;
    outline-offset: -3px;
  }
}
@media screen and (-ms-high-contrast: none) {
  .splide__slide:focus-visible {
    border: 3px solid #0bf;
  }
}
@supports (outline-offset: -3px) {
  .splide.is-focus-in .splide__slide:focus {
    outline: 3px solid #0bf;
    outline-offset: -3px;
  }
}
@media screen and (-ms-high-contrast: none) {
  .splide.is-focus-in .splide__slide:focus {
    border: 3px solid #0bf;
  }

  .splide.is-focus-in .splide__track > .splide__list > .splide__slide:focus {
    border-color: #0bf;
  }
}
.splide__toggle {
  cursor: pointer;
}

.splide__toggle:focus-visible {
  outline: 3px solid #0bf;
  outline-offset: 3px;
}

.splide.is-focus-in .splide__toggle:focus {
  outline: 3px solid #0bf;
  outline-offset: 3px;
}

.splide__track--nav > .splide__list > .splide__slide {
  border: 3px solid transparent;
  cursor: pointer;
}

.splide__track--nav > .splide__list > .splide__slide.is-active {
  border: 3px solid #000;
}

.splide__arrows--rtl .splide__arrow--prev {
  left: auto;
  right: 1em;
}

.splide__arrows--rtl .splide__arrow--prev svg {
  transform: scaleX(1);
}

.splide__arrows--rtl .splide__arrow--next {
  left: 1em;
  right: auto;
}

.splide__arrows--rtl .splide__arrow--next svg {
  transform: scaleX(-1);
}

.splide__arrows--ttb .splide__arrow {
  left: 50%;
  transform: translate(-50%);
}

.splide__arrows--ttb .splide__arrow--prev {
  top: 1em;
}

.splide__arrows--ttb .splide__arrow--prev svg {
  transform: rotate(-90deg);
}

.splide__arrows--ttb .splide__arrow--next {
  bottom: 1em;
  top: auto;
}

.splide__arrows--ttb .splide__arrow--next svg {
  transform: rotate(90deg);
}

.splide__pagination--ttb {
  bottom: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  left: auto;
  padding: 1em 0;
  right: 0.5em;
  top: 0;
}

html {
  font-size: 16px;
}

body {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
}

@media (min-width: 1024px) {
  .menuActive .menu {
    visibility: visible;
    opacity: 1;
  }
}

@media (min-width: 1024px) {
  .menu {
    visibility: hidden;
    opacity: 0;
    transition: all 0.25s ease;
  }
  .menu.visible {
    visibility: visible;
    opacity: 1;
  }
}

.lineMenuIcon {
  position: fixed;
  width: 60px;
  height: 60px;
  right: 20px;
  top: 22px;
  transform: scale(0.7);
  transition-duration: 0.5s;
  z-index: 1000;
}
@media (max-width: 579px) {
  .lineMenuIcon {
    transform: scale(0.6);
    top: 12px;
    right: 25px;
  }
}
@media (max-width: 419px) {
  .lineMenuIcon {
    transform: scale(0.5);
    top: 12px;
    right: 8px;
  }
}
.lineMenuIcon .icon {
  transition-duration: 0.5s;
  position: absolute;
  height: 8px;
  width: 60px;
  top: 30px;
  background-color: #2E8120;
}
.lineMenuIcon .icon:before {
  transition-duration: 0.5s;
  position: absolute;
  width: 60px;
  height: 8px;
  background-color: #2E8120;
  content: "";
  top: -20px;
}
.lineMenuIcon .icon:after {
  transition-duration: 0.5s;
  position: absolute;
  width: 60px;
  height: 8px;
  background-color: #2E8120;
  content: "";
  top: 20px;
}
.lineMenuIcon.open .icon {
  transition-duration: 0.5s;
  background: transparent;
}
.lineMenuIcon.open .icon:before {
  transform: rotateZ(45deg) scaleX(1.25) translate(13px, 13px);
}
.lineMenuIcon.open .icon:after {
  transform: rotateZ(-45deg) scaleX(1.25) translate(12px, -12px);
}
.lineMenuIcon:hover {
  cursor: pointer;
}

.mobileMenu {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  z-index: 999;
  right: -100%;
  top: 0;
  bottom: 0;
  padding: 50px;
  backdrop-filter: blur(5px);
  transition: all 0.5s ease;
}
@media (min-width: 1024px) {
  .mobileMenu {
    width: 300px;
    right: -300px;
  }
}
.mobileMenu.active {
  right: 0;
}
.mobileMenu ul {
  list-style: none;
  padding: 0;
}
.mobileMenu ul li {
  margin: 10px 0;
}
.mobileMenu ul li a {
  text-decoration: none;
  font-weight: 600;
  font-size: 20px;
  color: white;
}

.mobileHeader {
  position: sticky;
  z-index: 99;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.6);
  transition: all 0.25s ease;
}
@media (min-width: 1024px) {
  .mobileHeader {
    display: none;
  }
}
.mobileHeader.fixed-header {
  border-bottom: 1px solid #d3d3d3;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(5px);
}
@media (max-width: 579px) {
  .mobileHeader {
    height: 100px;
  }
}
@media (max-width: 419px) {
  .mobileHeader {
    height: 90px;
  }
}
.mobileHeader .navBar .logo {
  padding-bottom: 10px;
  transform: scale(0.7);
  left: -81px;
  position: relative;
}
@media (max-width: 419px) {
  .mobileHeader .navBar .logo {
    transform: scale(0.6);
    left: -100px;
  }
}

.container-fluid {
  padding-left: 50px;
  padding-right: 50px;
}
@media (max-width: 640px) {
  .container-fluid {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.section {
  padding: 60px 0;
}
.section:nth-child(2n+1) {
  background-color: #f6f6f6;
}
.section#home {
  padding-top: 0px;
}
@media (min-width: 1024px) {
  .section {
    min-height: 100vh;
  }
}
.section .titleBar {
  margin-bottom: 40px;
}
.section .titleBar h2 {
  font-size: 3.5rem;
  margin-bottom: 10px;
}
@media (max-width: 767px) {
  .section .titleBar h2 {
    font-size: 2.8rem;
    margin-bottom: 20px;
  }
}
@media (max-width: 579px) {
  .section .titleBar h2 {
    font-size: 9vw;
    margin-bottom: 20px;
  }
}
.section .titleBar p {
  font-weight: 500;
  font-size: 1.2rem;
}
.section h2 {
  font-size: 64px;
  color: #589A4D;
  font-weight: 700;
  margin-bottom: 20px;
}

a {
  text-decoration: none;
  color: #2E8120;
}

.link-btn {
  color: rgba(46, 129, 32, 0.8);
  font-size: 3rem;
  font-weight: 900;
  background: #fff;
  border: 2px solid #000;
  padding: 10px 40px;
  border-radius: 50px;
  text-decoration: none;
  transition: all 0.25s ease;
}
.link-btn:hover {
  background-color: rgba(46, 129, 32, 0.8);
  border-color: rgba(46, 129, 32, 0.8);
  color: #fff;
}
@media (max-width: 579px) {
  .link-btn {
    font-size: 8.8vw;
    padding: 15px 40px;
  }
}
@media (max-width: 579px) {
  .link-btn {
    font-size: 7vw;
  }
}

.innerpageContent {
  min-height: 70vh;
  padding: 40px 0;
  background-color: #f6f6f6;
}

.footer {
  background: white !important;
  border-top: 1px solid #d3d3d3 !important;
  padding: 50px 0;
}
.footer p {
  margin-bottom: 0;
}
.footer .social {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}
.footer .social a {
  display: inline-block;
  margin-left: 10px;
}
.footer .footerMenu {
  margin-bottom: 5px;
}
.footer .footerMenu a {
  text-decoration: none;
  color: #2E8120;
  margin: 0 5px;
}

.logo {
  width: 418px;
}
@media (max-width: 579px) {
  .logo {
    position: relative;
    left: -60px;
    top: -10px;
    transform: scale(0.7);
  }
}
.logo a {
  display: inline-block;
  text-decoration: none;
  color: #000;
}
.logo .logoItems {
  position: relative;
  width: 119px;
  overflow: hidden;
  animation: logoAnimate 2.5s ease-in-out 1 0.5s forwards;
}
.logo .logoItems .carlogo {
  position: absolute;
  top: 0;
  right: 0;
}
.logo .logoItems .carlogo:after {
  content: "";
  position: absolute;
  top: 19px;
  bottom: 12px;
  background-color: #fff;
  animation: transport 2.5s ease-in-out 1 0.5s forwards;
  display: block;
  left: 0;
  right: 0;
  z-index: 1;
}
.logo .logoItems .carlogo .transport {
  position: relative;
  z-index: 2;
}
.logo .logoItems .logoPic {
  width: 418px;
  height: auto;
  max-width: 418px;
  min-width: 418px;
}

@media (max-width: 1023px) {
  #home .navBar {
    display: none;
  }
}

.navBar {
  transition: background-color ease 0.5s;
  top: 0;
  left: 0;
  z-index: 99;
}
.navBar .logo {
  padding-bottom: 20px;
}
.navBar .logo h1 {
  display: none;
}
.navBar .logo h2 {
  color: #000;
  font-size: 18px;
  font-weight: 700;
  margin-top: -8px;
  margin-left: 10px;
  position: relative;
  z-index: 9;
}
@media (min-width: 1024px) {
  .navBar .menuItems {
    text-align: right;
    margin-top: 44px;
  }
  .navBar .menuItems ul {
    list-style: none;
    padding: 0;
  }
  .navBar .menuItems ul li {
    display: inline-block;
    margin-left: 20px;
  }
  .navBar .menuItems ul li a {
    text-decoration: none;
    font-weight: 600;
    font-size: 18px;
    color: black;
  }
}
@media (min-width: 1150px) and (max-width: 1235px) {
  .navBar .menuItems ul li {
    margin-left: 15px;
  }
  .navBar .menuItems ul li a {
    font-size: 16px;
  }
}
@media (min-width: 1024px) and (max-width: 1149px) {
  .navBar .menuItems ul li {
    margin-left: 10px;
  }
  .navBar .menuItems ul li a {
    font-size: 15px;
  }
}
@media (max-width: 1023px) {
  .navBar .menuItems ul {
    display: none;
  }
}

.banner {
  text-align: center;
  padding: 80px 0;
}
.banner h2 {
  font-size: 3.5rem;
  color: #000000;
  text-shadow: 0 4px 4px #bbb;
  margin-bottom: 25px;
}
@media (max-width: 767px) {
  .banner h2 {
    font-size: 2.8rem;
  }
}
@media (max-width: 579px) {
  .banner h2 {
    font-size: 8vw;
    margin-bottom: 40px;
  }
}
.banner p {
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 50px;
}
@media (max-width: 579px) {
  .banner p {
    font-size: 5vw;
    margin-bottom: 70px;
  }
}
.banner .bannerImage {
  margin-top: 80px;
}

.about .pic {
  width: 235px;
  margin: 0 auto;
}
.about .pic img {
  max-width: 100%;
  height: auto;
}
.about .why {
  text-align: center;
  padding-top: 40px;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}
.about .why h3 {
  text-align: center;
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .about .why h3 {
    font-size: 2rem;
  }
}
@media (max-width: 579px) {
  .about .why h3 {
    font-size: 8vw;
  }
}
.about .why .splide {
  margin-bottom: 30px;
}
.about .why .splide .pic {
  margin-bottom: 10px;
}
.about .why .splide .pic img {
  width: 100%;
  max-width: 235px;
  margin: 0 auto;
}
.about .why .splide .text p {
  font-size: 16px;
}

.how {
  text-align: center;
}
.how h4 {
  font-size: 35px;
  font-weight: 400;
  line-height: 1.3;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto 30px;
}
.how .contentBox {
  position: relative;
  height: calc(100% - 150px);
}
.how .title {
  background: #589a4d;
  width: 120px;
  height: 120px;
  margin: 0 auto;
  border-radius: 100px;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 9;
  left: 0;
  right: 0;
  top: -60px;
}
.how .title p {
  font-size: 18px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 0;
}
.how .content {
  background-color: #fff;
  box-shadow: 0 5px 5px #d3d3d3;
  height: 100%;
  padding: 80px 25px 50px;
  margin-bottom: 80px;
  border-radius: 20px;
  border: 1px solid #d3d3d3;
  margin-top: 70px;
}
@media (max-width: 767px) {
  .how .content {
    height: auto;
    margin-top: 40px;
    margin-bottom: 50px;
  }
}

@media (max-width: 767px) {
  .service .col-md-4 {
    margin-bottom: 20px;
  }
}
.service .box {
  background-color: #fff;
  margin-top: 30px;
  height: 100%;
  padding: 40px;
  padding-bottom: 60px;
  text-align: center;
  border-radius: 20px;
  border: 1px solid #d3d3d3;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.25);
}
.service .box .icon img {
  width: 100%;
  max-width: 128px;
  margin: 0 auto 25px;
}
.service .box p {
  font-size: 16px;
}

@media (max-width: 767px) {
  .pricing .col-md-4 {
    margin-bottom: 20px;
  }
}
.pricing .box {
  background-color: #fff;
  height: 100%;
  padding: 40px;
  padding-bottom: 60px;
  text-align: center;
  border-radius: 20px;
  border: 1px solid #d3d3d3;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.25);
}

.section.contact {
  min-height: 82vh;
}

.contact .box {
  text-align: center;
  padding: 0 100px;
}
@media (max-width: 767px) {
  .contact .box {
    padding: 0 40px;
  }
}
@media (max-width: 579px) {
  .contact .box {
    padding: 0 20px;
  }
}
.contact .box img {
  margin: 0 auto 10px;
}
.contact .box h3 {
  font-weight: 700;
  margin-bottom: 20px;
}
.contact .box p {
  margin-bottom: 20px;
}

@keyframes moveFromTo {
  0% {
    top: -100%;
  }
  100% {
    top: 0;
  }
}
@keyframes logoAnimate {
  0% {
    width: 129px;
  }
  100% {
    width: 418px;
  }
}
@keyframes transport {
  0% {
    background-color: #fff;
  }
  99% {
    background-color: #fff;
  }
  100% {
    background-color: inherit;
  }
}